import { HOC_MDXWrapper } from 'nextra/setup-page'
import { pageMap } from '/home/runner1/actions-runner/_work/Astrolab/Astrolab/frontend/web/landing/.next/static/chunks/nextra-page-map-en.mjs'

/*@jsxRuntime automatic*/
/*@jsxImportSource react*/
import {useMDXComponents as _provideComponents} from "nextra/mdx";
const title = "OctoBot developers";
const frontMatter = {
  "description": "Wondering how to deeply customize OctoBot ? Learn how to start as an OctoBot developer, create your own tentacles and contribute to the open source trading robot"
};
import {Callout} from 'nextra/components';
import {NextraLocalizedLink} from '@astrolab/web/common/LocalizedLink';
export function useTOC(props) {
  return [{
    value: "1 - Learn the basics of Python",
    id: "1---learn-the-basics-of-python",
    depth: 2
  }, {
    value: "2 - Setup your OctoBot developer environment",
    id: "2---setup-your-octobot-developer-environment",
    depth: 2
  }, {
    value: "3 - Learn how to create Tentacles",
    id: "3---learn-how-to-create-tentacles",
    depth: 2
  }, {
    value: "4 - Contribute to OctoBot",
    id: "4---contribute-to-octobot",
    depth: 2
  }];
}
function MDXLayout(props) {
  const {toc = useTOC(props)} = props;
  const _components = {
    a: "a",
    h1: "h1",
    h2: "h2",
    p: "p",
    ..._provideComponents(),
    ...props.components
  };
  return <><_components.h1>{"OctoBot developers"}</_components.h1>{"\n"}<Callout type="info" emoji=""><_components.p>{"For developers and contributors to the "}<NextraLocalizedLink href="https://github.com/Drakkar-Software/OctoBot" isExternal noFollow>{"open source OctoBot"}</NextraLocalizedLink>{"."}</_components.p></Callout>{"\n"}<_components.h2 id={toc[0].id}>{toc[0].value}</_components.h2>{"\n"}<_components.p>{"Watching one hour of a beginner Python video course should be enough to get started."}</_components.p>{"\n"}<_components.h2 id={toc[1].id}>{toc[1].value}</_components.h2>{"\n"}<_components.p>{"Once setup you’ll love the "}<_components.a href="/en/guides/octobot-developers-environment/setup-your-environment">{"OctoBot Developer environment"}</_components.a>{"."}</_components.p>{"\n"}<_components.h2 id={toc[2].id}>{toc[2].value}</_components.h2>{"\n"}<_components.p>{"OctoBot Tentacles are apps/extensions for OctoBot."}</_components.p>{"\n"}<_components.p>{"A tentacle can be what ever you want it to be. For example an Indicator, a Strategy, a new\nFeature for the web interface or for something else. All of these can be packed in one tentacle bundle."}</_components.p>{"\n"}<_components.p>{"Check out out the "}<_components.a href="/en/guides/octobot-tentacles-development/customize-your-octobot">{"OctoBot customization guide"}</_components.a>{"."}</_components.p>{"\n"}<_components.h2 id={toc[3].id}>{toc[3].value}</_components.h2>{"\n"}<_components.p>{"Have a look at our "}<NextraLocalizedLink href="https://github.com/Drakkar-Software/OctoBot/blob/master/CONTRIBUTING.md" isExternal noFollow>{"contribution guidelines"}</NextraLocalizedLink>{"."}</_components.p></>;
}


export default HOC_MDXWrapper(
  MDXLayout,
  '/en/guides/developers',
  {"filePath":"pages/en/guides/developers.mdx","timestamp":1731779197000,pageMap,frontMatter,title},
  typeof RemoteContent === 'undefined' ? useTOC : RemoteContent.useTOC
)